const initialState = {
  message: "Hello, world!",
};

const exampleReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default exampleReducer;
